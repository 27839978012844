import React from 'react'
import { Link, graphql} from 'gatsby'
import Layout from '../../components/Layout';
import { StructuredText } from 'react-datocms';
import ContactButton from '../../components/ContactBtn';
import SEO from '../../components/SEO'



export default function Template({data}) {
  let {longdescription, reviewvideo, jobtype, shortreview, posttitle } = data.allDatoCmsReview.nodes[0];

// dynamic post page gets data from dynamic query using query variables in the file name 
  // the longreview section is a wysiwyg editor 

  return (
    <Layout>
      <SEO title={posttitle} description={shortreview}/>
        <div className=' mx-auto w-3/5'>
              <figure className="relative video">
                <iframe className="absolute inset-0 w-full h-full" title='Youtube Review Video' src={reviewvideo} frameborder="0" key={reviewvideo.id} ></iframe>
          </figure>
        <div className='flex flex-wrap my-3'>
          <span className='w-full'>
          {/* <p className='inline-block text-sm px-2 py-1 rounded-full shadow hover:shadow-md outline-none btnColor'>{jobtype}</p> */}
          </span>
          <span style={{fontFamily: "linotype-didot, serif", fontSize: "30px"}}>{posttitle}</span>
          <StructuredText style={{fontFamily: "Josefin sans, sans-serif", fontSize: "16px"}} data={longdescription.value} />
          <span className='w-full flex justify-end mb-4'>
          <Link style={{fontFamily: "Josefin sans, sans-serif", color: "white", textTransform: "uppercase", margin: "auto"}} className='py-2 px-4 btnColor no-underline my-2 ' to='/#contact'>Contact Us</Link>
          </span>
        </div>
            
        </div>
      </Layout>
    )
}

export const pageQuery = graphql`
query Reviews($id: String){
        allDatoCmsReview(filter: {id: {eq: $id}}) {
          nodes {
            jobtype
            reviewvideo
            shortreview
            posttitle
            longdescription {
              value
            }
          }
        }
      }
`


